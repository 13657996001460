import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CiCalendarDate } from "react-icons/ci";
import { MdGpsFixed } from "react-icons/md";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { getImageUrl } from "../../index.js";
import { GetEvenement, Evnt_OG } from "../../Redux/Slice/EvenementSlice";
import logo from "../../images/logo.jpg";
import "./css/evenement.css";
import { Helmet } from "react-helmet";

const EvenementPartage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loadedEvent, setLoadedEvent] = useState(null);
  const [ogMetaData, setOgMetaData] = useState({});

  const { Evenement } = useSelector((state) => state.evenement);
  const { html } = useSelector((state) => state.og);

  useEffect(() => {
    dispatch(GetEvenement(id));
    console.log(id);
  }, [dispatch, id]);

  const event = Evenement.find((evt) => evt._id === id);

  useEffect(() => {
      setLoadedEvent(event);
      dispatch(Evnt_OG(event._id)); // Update OG metadata
    
  }, [Evenement, id, event, dispatch]);

  useEffect(() => {
    if (html) {
      // Parse the HTML content
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      // Extract the og meta tags
      const ogTitle = doc.querySelector('meta[property="og:title"]')?.getAttribute("content") || "Evenement";
      const ogImage = doc.querySelector('meta[property="og:image"]')?.getAttribute("content") || "";
      const ogUrl = doc.querySelector('meta[property="og:url"]')?.getAttribute("content") || "";

      // Set the extracted data
      setOgMetaData({ ogTitle, ogImage, ogUrl });
    }
  }, [html]);

  const share_Url = loadedEvent ? `https://moncoach.tn/Evenement/${loadedEvent._id}` : "";
  const ogTitle = ogMetaData.ogTitle;
  const ogImage = ogMetaData.ogImage;
  const ogUrl = ogMetaData.ogUrl;

  if (!loadedEvent) return <div>Loading...</div>;

  return (
    <>
      <Helmet>
        <title>{ogTitle}</title>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="website" /> 
        <meta property="fb:app_id" content="332085763115778" /> 
      </Helmet>
      <div style={{ padding: "40px" }}>
        <img src={logo} alt="logo" width="220px" height="70" />
        <hr />
        <div>
          <img src={getImageUrl(loadedEvent.photo)} alt="Event" className="Evnt-image" />
        </div>
        <div className="modal-Evnt">
          <h2 className="Evnt-titre">{loadedEvent.titre}</h2>
          <div className="Evnt-descri" dangerouslySetInnerHTML={{ __html: loadedEvent.texte }} />
          <div className="Evnt-info">
            <div className="info-item">
              <CiCalendarDate className="info-icon" />
              <h5 className="info-date">{loadedEvent.dates}</h5>
            </div>
            <div className="info-item">
              <MdGpsFixed className="info-icon" />
              <h5 className="info-lieu">{loadedEvent.lieu}</h5>
            </div>
          </div>
          <div className="partagerEVNT">
            <div>
              <FacebookShareButton url={share_Url} quote={ogTitle}>
                <div className="share-button">
                  <FacebookIcon style={{ color: "#fff" }} size={20} />
                  <h3 className="info-item">Partage</h3>
                </div>
              </FacebookShareButton>
            </div>
            <div>
              <LinkedinShareButton url={share_Url}>
                <div className="share-button">
                  <LinkedInIcon style={{ color: "#fff" }} size={20} />
                  <h3 className="info-item">Partage</h3>
                </div>
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EvenementPartage;
