import React from 'react'
import BarheaderProfil from './BarheaderProfil'
import { NavBar } from './NavBar'
import Edit from './Edit'
import Newsletter from './Newsletter'
import Footer from './Footer'

const EditProfile = () => {
  return (
<>
<BarheaderProfil/>
       <NavBar/>
<Edit/>
<Newsletter/>
<Footer/>

</>  )
}

export default EditProfile