import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get_List } from "../../../Redux/Slice/ListSlice";
import { useNavigate, useParams } from "react-router-dom";
import './css/view.list.css'

const ViewEntrep = () => {
    const navigate=useNavigate()

    const dispatch = useDispatch();
    const { id } = useParams(); 
    const { Lists_EN_COACH  } = useSelector((state) => state.list);
  
    useEffect(() => {
        console.log(id)
      dispatch(Get_List({ id, entreprise: "Entreprise" })); 
    }, [dispatch, id]);
  const [formData, setFormData] = useState({
    titre: "",
    texte: "",
    lien: "",
    auteur: "",
    photo: "",
  });

  useEffect(() => {
    if (Lists_EN_COACH && id) {
      const pub = Lists_EN_COACH.find((list) => list._id === id);
      if (pub) {
        setFormData(pub);
      }
    }
    
  }, [Lists_EN_COACH , id]);
  return (
    <div className="View">
      <div className="viewContainer">
        <div
          style={{
            borderBottom: "solid 1px rgb(194, 192, 192)",
            width: "100%",
            padding: "5px",
          }}
        >
          <label className="labelViewArt">Nom</label>
          <p className="styletexteART">{formData.nom}</p>
        </div>

        <div
          style={{
            borderBottom: "solid 1px rgb(194, 192, 192)",
            width: "100%",
            padding: "5px",
          }}
        >
          <label className="labelViewArt">prenom</label>
          <p className="styletexteART">{formData.prenom}</p>
        </div>

        <div
          style={{
            borderBottom: "solid 1px rgb(194, 192, 192)",
            width: "100%",
            padding: "5px",
          }}
        >
          <label className="labelViewArt">Tel</label>
          <p className="styletexteART">{formData.tel}</p>
        </div>
        <div
          style={{
            borderBottom: "solid 1px rgb(194, 192, 192)",
            width: "100%",
            padding: "5px",
          }}
        >
          <label className="labelViewArt">Mail</label>
          <p className="styletexteART">{formData.mail}</p>
        </div>
        <div
          style={{
            borderBottom: "solid 1px rgb(194, 192, 192)",
            width: "100%",
            padding: "5px",
          }}
        >
          <label className="labelViewArt">entreprise</label>
          <p className="styletexteART">{formData.entreprise}</p>
        </div>
        <div
          style={{
            borderBottom: "solid 1px rgb(194, 192, 192)",
            width: "100%",
            padding: "5px",
          }}
        >
          <label className="labelViewArt">Poste</label>
          <p className="styletexteART">{formData.poste}</p>
        </div>
        <div style={{ width: "100%", padding: "5px" }}>
          <label className="labelViewArt">Proposition</label>
          <p className="styletexteART">{formData.proposition}</p>
        </div>
      </div>

      <button
        className="AnnuleView"
        onClick={() => navigate("/admin/atelier-A")}
      >
        Retour
      </button>
    </div>
  );
};



export default ViewEntrep