import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import './css/editTemoignage.css';
import { useEffect, useRef, useState } from 'react';
import loadingGif from './../../../images/loading.gif';
import { GetTemoignageV, PutTemoignagesV } from '../../../Redux/Slice/TemoignegeSlice';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditTemoignage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { TemoignegeV } = useSelector((state) => state.temoignage);
    const [Texte, setTexte] = useState('');

    const [formData, setFormData] = useState({
        nom: '',
        texte: '',
        Date: '',
    });

    useEffect(() => {
        dispatch(GetTemoignageV());
    }, [dispatch]);

    useEffect(() => {
        if (TemoignegeV && id) {
            const TemoignegeVEdit = TemoignegeV.find(T_V => T_V._id === id);
            if (TemoignegeVEdit) {
                setFormData(TemoignegeVEdit);
                setTexte(TemoignegeVEdit.texte); // Initialize the texte state with the existing content
            }
        }
    }, [TemoignegeV, id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(PutTemoignagesV({ id, data: { nom: formData.nom, texte: Texte } }));
        navigate('/admin/témoignages/visible');
    };

    if (!TemoignegeV) { // Adjust loading condition as needed
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src={loadingGif} alt="Chargement..." />
            </div>
        );
    }

    return (
        <div className='edit-Tem'>
            <div className='FormTemContainer'>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className='labelEditTem'>Nom</label>
                        <input
                            className='inputEditTem'
                            type='text'
                            name='nom'
                            value={formData.nom}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className='labelEditTem'>Texte</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={Texte}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTexte(data);
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className='labelEditTem'>Date</label>
                        <input
                            className='inputEditTem'
                            type='text'
                            name='Date' // Ensure the name is consistent
                            value={formData.Date}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='Bouton-Edit-Tem'>
                        <button type="submit" className='btn-EditTem'>Modifier</button>
                        <button type="button" className='btn-AnnulerTem' onClick={() => navigate('/admin/Accueil')}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditTemoignage;
