import React, { useEffect } from 'react';
import image from '../../images/big_image_2.jpg';
import Footer from '../coach/Footer';
import Newsletter from '../coach/Newsletter';
import { getFaq } from '../../Redux/Slice/FaqSlice';
import { useDispatch, useSelector } from 'react-redux';
import './css/faq.css'; // Import the CSS file
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
const FaqCoach = () => {
    const { Faqs } = useSelector((state) => state.faq);

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getFaq());
    }, [dispatch]);

    return (
        < >
            <div
                className="PlatformePartageTem section-hero"
                style={{ backgroundImage: `url(${image})` }}
            >
                <div>
                    <h3 className="TemTitre">FAQ</h3>
                </div>
            </div>

          <div className='FAQ'>
          <div className="faq-container">
            <QuestionMarkIcon style={{fontSize:'60px'}} className='FAQIcon'/>
                {Faqs && 
                    Faqs.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <h4 className="faq-question">{faq.question}</h4>
                            <p className="faq-answer">{faq.rreponse}</p>
                        </div>
                    ))
                }
            </div>


          </div>
            <Newsletter />
            <Footer />
        </>
    );
};

export default FaqCoach;
