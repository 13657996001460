import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasword } from "../../Redux/Slice/CoachSlice"; // Ensure this is the correct import
import './css/ResetPassword.css';

const ResetPassword = () => {
  const { token } = useParams(); 
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      setError("Le jeton de réinitialisation est manquant.");
    }
  }, [token]);

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }


    dispatch(resetPasword({ token: token, newPassword, confirmPassword }));
  navigate("/coach/login")}

  return (
    <div className="reset-pasword">

<div className="reset-password-container">
      <h2 className="reset">Réinitialiser le mot de passe</h2>
      <hr/>
      <form onSubmit={handleSubmit} className="reset-password-form">
        <div className="form-group">
          <label  className="form-group-label" htmlFor="newPassword">Nouveau mot de passe :</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            className="form-group-input"
            placeholder="Enter your new password"
            value={newPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-group-label" htmlFor="confirmPassword">Confirmer le mot de passe :</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className="form-group-input "
            placeholder="Confirm your password"

            value={confirmPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <button  className="reset-button" type="submit">Réinitialiser </button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
    </div>
  );
};

export default ResetPassword;
