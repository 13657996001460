import React, { useEffect } from "react";
import image from "../../images/big_image_2.jpg";
import "./css/espaceCoach.css";
import image1 from "../../images/article.jpg";
import { IoMdPlay } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Newsletter from "../coach/Newsletter";
import Footer from "../coach/Footer";

const EspaceCoach = () => {
  const navigate = useNavigate();
  const handleFormulaire = () => navigate("/formulaire");
  const handleAddArticle = () => navigate("/ajouter_article");
  const handleARTICLES = () => navigate("/articles");
  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top +25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className="ImagePlatforme section-hero"
        style={{
          position: "relative",
          textAlign: "center",
          height: "300px",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100%" }}>
          <h3 className="coach_Titre">Vous êtes coach?</h3>
          <form className="FORMECOACH">
            <div className="fieldsCOACH d-block d-lg-flex">
              <h3 className="formulairecoach">
                Rejoignez votre plateforme
                <button
                  className="remplirFormulaire"
                  onClick={handleFormulaire}
                >
                  Formulaire à remplir
                </button>
              </h3>
            </div>
          </form>
        </div>
      </div>

      <div className="Add-Article ">
        <button className="remplir-Article" onClick={handleAddArticle}>
          Partagez article, offre
        </button>

        <div className="ARTICLEright">
          <h2 className="ParcourArticle">
            Parcourez les articles, les offres d'emploi!
          </h2>
          <div className="ContainerArticle" onClick={handleARTICLES} style={{ cursor: 'pointer' }}>
            <div className="image-container">
              <img
                src={image1}
                alt=" placeholder"
                className="image-Article"
              />
              <IoMdPlay  size={"70px"} className="icon-play" />
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default EspaceCoach;
