import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>Not Found
</h1>
      <h5>The requested URL was not found on this server.</h5>
    </div>
  );
}

export default NotFound;
