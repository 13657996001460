import React from 'react';
import { useSelector } from 'react-redux';
import Barheader from './Barheader';
import BarheaderProfil from './BarheaderProfil';

const Header = () => {
  const { isAuth } = useSelector((state) => state.coach);


 
  return (
    <div>
      {isAuth ? <BarheaderProfil /> : <Barheader />}
    </div>
  );
}

export default Header;
