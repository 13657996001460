import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import {
  FacebookShareButton,
 
  LinkedinShareButton,

} from "react-share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import {  BASE_URL_back } from "../../index.js";

import "./css/temoignage.css";
import { GetTemoignageV } from "../../Redux/Slice/TemoignegeSlice.js";
import { TEM_OG } from "../../Redux/Slice/OGSlice.js";
const PartageTem = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [loadedTem, setLoadedTem] = useState(null);
    const { TemoignegeV } = useSelector((state) => state.temoignage);
  
    useEffect(() => {
      dispatch(GetTemoignageV());
    }, [dispatch]);
  
    const Tem = TemoignegeV.find((tem) => tem._id === id);

    useEffect(() => {
      setLoadedTem(Tem);
      if (Tem) {
        dispatch(TEM_OG(Tem._id));
      }
    }, [Tem, dispatch]);
  
    const share_Url = loadedTem ? `${BASE_URL_back}/Temoignages/${id}` : '';
    useEffect(() => {
      const handleScroll = () => {
        const elem = document.querySelector('.section-hero');
        const { top } = elem.getBoundingClientRect();
        if (elem) {
          const backgroundPositionY = -(top +25) * 0.6;
          elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    return (
      <div style={{ padding: "40px" }}>
        <IoMdPerson size={80} className="temoignage-icon" />
        <div className="temoignage-content">
          <h4 className="nom-TEM">{loadedTem?.nom}</h4>
          <p 
            className="nom-TEXTE" 
            dangerouslySetInnerHTML={{ 
              __html: loadedTem?.texte 
            }}
          ></p>
          <div className="partagerTEM section-hero">
            {share_Url && (
              <>
                <div>
                  <FacebookShareButton url={share_Url}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#0965FE",
                        paddingRight: "5px",
                      }}
                    >
                      <FacebookIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </FacebookShareButton>
                </div>
                <div>
                  <LinkedinShareButton url={share_Url}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#0077B5",
                        paddingRight: "5px",
                      }}
                    >
                      <LinkedInIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </LinkedinShareButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default PartageTem;
  