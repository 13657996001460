import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MdPerson } from "react-icons/md";
import {
  FacebookShareButton,

  LinkedinShareButton
} from "react-share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { BASE_URL_back, getImageUrl } from "../../index.js";
import logo from "../../images/logo.jpg";
import { GetArticle } from "../../Redux/Slice/ArticleSlice.js";
import { Artc_OG } from "../../Redux/Slice/ArticleSlice.js";
import "./css/article.css"
const PartageArticle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loadedArt, setLoadedArt] = useState(null);

  const { Articles } = useSelector((state) => state.article);

  const art =Articles.find((artic) => artic._id === id)
  

  useEffect(() => {
      dispatch(GetArticle(id)); // Fetch article by ID if not already available in state
    

  }, [dispatch,id]);
  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top +25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
      setLoadedArt(art);
      if (art) {

      dispatch(Artc_OG(art._id));

    }
  }, [art,dispatch]);
  

  // Default values if art is undefined
  const shareUrl = loadedArt ? `${BASE_URL_back}/articles/${id}` : '';
  const ogImage = art ? getImageUrl(art.photo) : "";
  const ogTitle = art ? art.titre : "";
  const ogDescription = art ? art.texte : "";

  return (
    <>
      
        <div style={{ padding: "40px" }}>
          <img src={logo} alt="Article" style={{ width: "180px" }} />
          <hr />
          <img
            src={ogImage}
            alt="Article"
            style={{ display: "block", margin: "0 auto", width: "200px" }}
          />
          <h3 className="ART-titre">{ogTitle}</h3>
          <div
            className="Art-Descrip"
            dangerouslySetInnerHTML={{ __html: ogDescription }}
          />
          <div className="Artc-inf">
            <div className="Artc-author">
              <div style={{ paddingLeft: "30px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MdPerson className="artic-author-icon" />
                  <h5 className="articl-auteur">{art.auteur}</h5>
                </div>
                <div
                  className="partageArticle"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <div>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={ogTitle}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#0965FE",
                          paddingRight: "5px",
                        }}
                      >
                        <FacebookIcon style={{color:"#fff" }}  size={20} />
                        <h3 className="info-item">Partage</h3>
                      </div>
                    </FacebookShareButton>
                  </div>
                  <div>
                    <LinkedinShareButton url={shareUrl}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#0077B5",
                          paddingRight: "5px",
                        }}
                      >
                        <LinkedInIcon style={{color:"#fff" }}  size={20} />
                        <h3 className="info-item">Partage</h3>
                      </div>
                    </LinkedinShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
    </>
  );
};

export default PartageArticle;
