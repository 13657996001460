import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './CSS/editslider.css';
import { GetSlides, PutSlider } from '../../../Redux/Slice/SlidesSlice';
import loadingGif from './../../../images/loading.gif';
import { getImageUrl } from '../../..';

const EditSlider = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { Slide } = useSelector((state) => state.slide);

    const [formData, setFormData] = useState({
        titre1: '',
        titre2: '',
        photo: ''
    });
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        setEditorLoaded(true);
        dispatch(GetSlides());
    }, [dispatch]);

    useEffect(() => {
        if (Slide && id) {
            const SlideToEdit = Slide.find(slide => slide._id === id);
            if (SlideToEdit) {
                setFormData({
                    titre1: SlideToEdit.titre1,
                    titre2: SlideToEdit.titre2,
                    photo: SlideToEdit.photo
                });
            }
        }
    }, [Slide, id]);

    const handleTitre1Change = (event, editor) => {
        const data = editor.getData();
        setFormData(prevData => ({
            ...prevData,
            titre1: data
        }));
    };

    const handleTitre2Change = (event, editor) => {
        const data = editor.getData();
        setFormData(prevData => ({
            ...prevData,
            titre2: data
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(PutSlider({ id, data: formData }));
        navigate('/admin/editer_slider');
    };

    if (!editorLoaded) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src={loadingGif} alt="Chargement..." />
            </div>
        );
    }

    return (
        <div className='edit-Slider'>
            <div className='EditContainerSlider'>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='LabelEditSlider'>Titre1</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.titre1}
                            onChange={handleTitre1Change}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                height: 500,
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className='LabelEditSlider'>Titre2</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.titre2}
                            onChange={handleTitre2Change}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                height: 500,
                            }}
                        />
                    </div>

                    <div className="form-group-Article">
                        <label className='LabelEditSlider'>Image</label>
                        {formData.photo && (
                            <img
                                src={getImageUrl(formData.photo)}
                                alt="Icone"
                                className='imageEdit-Article'
                            />
                        )}
                    </div>
                    <div className='buttonsContainer'>
                        <button type="submit" className='btnEditSlider'>Modifier</button>
                        <button type="button" className='btnAnnuleSlider' onClick={() => navigate('/admin/Accueil')}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditSlider;
