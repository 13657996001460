import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import Spinner from "react-bootstrap/Spinner"; // Importer le Spinner de Bootstrap
import { useDispatch, useSelector } from "react-redux";
import { GetSlides } from "../../Redux/Slice/SlidesSlice";
import "./css/slide.css";
import { getImageUrl } from "../..";

const Slide = () => {
  const dispatch = useDispatch();
  const { Slide, isLoading } = useSelector((state) => state.slide);

  useEffect(() => {
    dispatch(GetSlides());
  }, [dispatch]);

  const truncateText = (htmlText, maxLength) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, "text/html");
    const textContent = doc.body.textContent || "";
    return textContent.length > maxLength
      ? textContent.substring(0, maxLength) + "..."
      : textContent;
  };

  // Afficher l'indicateur de chargement si isLoading est vrai
  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Carousel>
      {Slide &&
        Slide.map((slide, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100 carousel-img"
              src={getImageUrl(slide.photo)}
              alt={`Slide ${index + 1}`}
            />
            <Carousel.Caption>
              <h3 className="slide-title">{truncateText(slide.titre1, 100)}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default Slide;
