import React, { useEffect, useState } from "react";
import image from "../../images/big_image_2.jpg";
import "./css/temoignage.css";
import { GetTemoignageV } from "../../Redux/Slice/TemoignegeSlice";
import { useDispatch, useSelector } from "react-redux";
import Newsletter from "../coach/Newsletter";
import Footer from "../coach/Footer";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'; 
import { IoMdPerson } from "react-icons/io";
import {
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { BASE_URL_back } from "../..";
import { TEM_OG } from "../../Redux/Slice/OGSlice";

const Temoignages = () => {
  const { TemoignegeV } = useSelector((state) => state.temoignage);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedTemoignage, setSelectedTemoignage] = useState(null);

  useEffect(() => {
    dispatch(GetTemoignageV());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTemoignage) {
      console.log("Selected Temoignage:", selectedTemoignage);
    }
  }, [selectedTemoignage]);

  const handleClickOpen = (temoignage) => {
    setSelectedTemoignage(temoignage);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTemoignage(null);
  };
  useEffect(() => {
    if (selectedTemoignage) {
      dispatch(TEM_OG(selectedTemoignage._id));

    }

  }, [selectedTemoignage, dispatch]);
  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top +25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const share_Url = selectedTemoignage ? `${BASE_URL_back}/Temoignages/${selectedTemoignage._id}` : '';
  return (
    <>
      <div
        className="PlatformePartageTem section-hero"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div>
          <h3 className="TemTitre">Témoignages</h3>
        </div>
      </div>

      <div className="temoign">
        <div className="temoignages-container">
          {TemoignegeV && TemoignegeV.map((tem, index) => (
            <div key={index} className="temoignage-item">
              <IoMdPerson size={80} className="temoignage-icon" />
              <div className="temoignage-content">
                <h4 className="nom-TEM">{tem.nom}</h4>
                <p className="nom-TEXTE" dangerouslySetInnerHTML={{ __html: tem.texte.substring(0, 200) + "..." }}></p>
                <button onClick={() => handleClickOpen(tem)} className="temoignage-button">
                  Afficher la suite...
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <IconButton 
            aria-label="close" 
            onClick={handleClose} 
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="temoignage-item">
            <IoMdPerson size={80} className="temoignage-icon" />
            <div className="temoignage-content">
              <h4 className="nom-TEM">{selectedTemoignage?.nom}</h4>
              <p 
                className="nom-TEXTE" 
                dangerouslySetInnerHTML={{ 
                  __html: selectedTemoignage?.texte
                }}
              ></p>
              <div className="partagerTEM">
                <div>
                  <FacebookShareButton url={share_Url}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#0965FE",
                        paddingRight: "5px",
                      }}
                    >
                      <FacebookIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </FacebookShareButton>
                </div>
                <div>
                  <LinkedinShareButton url={share_Url}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#0077B5",
                        paddingRight: "5px",
                      }}
                    >
                      <LinkedInIcon style={{ color: "#fff" }} size={20} round />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Newsletter />
      <Footer />
    </>
  );
};

export default Temoignages;
