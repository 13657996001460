import React from 'react'

import Newsletter from './Newsletter'
import Footer from './Footer'
import Setting from './Setting'

const SettingProfil = () => {
  return (
<>
<Setting/>
<Newsletter/>
<Footer/>
</>

)
}

export default SettingProfil