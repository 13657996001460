import React, { useEffect } from "react";
import BarheaderAdmin from "../BarheaderAdmin";
import NavBarAdmin from "../NavBarAdmin";
import "./css/coachVisib.css"; // Importez le fichier CSS externe
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlinePaid } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrView } from "react-icons/gr";
import { FiSend } from "react-icons/fi";
import { delCoachVisible, getCoachVisivble, UpdateCoachAdmin } from "../../../Redux/Slice/CoachSlice";
import { getImageUrl } from "../../..";
import Deconnection from "../Deconnection";
import { sendEmail } from "../../../Redux/Slice/emailSlice"; // Assurez-vous que l'importation est correcte

const CoachVisib = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { coachVisible } = useSelector((state) => state.coach);

  useEffect(() => {
    dispatch(getCoachVisivble());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this coach?")) {
      dispatch(delCoachVisible({ id }));
    }
  };

  const handlesend = (email) => {
    const templateParams = {
      email: email,
      subject: "Votre Sujet du Message",
      message: `
        <div style="font-family: Arial, sans-serif; max-width: 600px; color: #000; margin: 0 auto; padding: 20px;">
          <h5>Bonjour,</h5>
          
          <p style=" color: #000">Nous vous remercions pour votre inscription sur la plateforme : <a href="https://moncoach.tn" style="color: blue; ">MonCoach.tn</a></p>
          
          <p>Veuillez trouver ci-joint le lien vers votre profil : <a href="https://moncoach.tn/coach/login" style="color: blue; ">Lien vers le profil</a></p>
          
          <p>Merci pour votre confiance !</p>
          
          <p >Ensemble pour une communauté plus grande</p>
          
        <img src="https://i.imgur.com/43ESnOv.jpg" alt="MonCoach.tn"  />
        </div>
      `,
    };
  
    dispatch(sendEmail(templateParams))
      .then(() => {
        alert("Email envoyé avec succès !");
        navigate("/admin/Coachs/visible");
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        alert("Failed to send email: " + error.message);
        navigate("/admin/Coachs/visible");
      });
  };
  
  const handlePaye= (id) => {
      dispatch(UpdateCoachAdmin({ id, data: { paye: true } }));
    
  };
  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />
      <Deconnection />

      <div className="ConsultCoch">
        <div className="ConsultCochcontainer">
          <table className="TableCoch">
            <thead>
              <tr>
                <th className="TableHeaderC">Nom et prénom</th>
                <th className="TableHeaderC">Domaines d’intervention</th>
                <th className="TableHeaderC">Photo</th>
                <th className="TableHeaderC">Action</th>
              </tr>
            </thead>
            <tbody>
  {coachVisible.map((coachV, index) => (
    <tr key={index}>
      <td 
        className="TableDataC" 
        style={{ backgroundColor: coachV.paye ? "#d4edda" : "inherit" }} 
      >
        {coachV.nom}

      </td>
      <td className="TableDataC TableDataText"         style={{ backgroundColor: coachV.paye ? "#d4edda" : "inherit" }} 
      >
        *{coachV.domain.join("\n * ")}
        {coachV.AutreDomaine && `\n * ${coachV.AutreDomaine}`}
        
      </td>
      <td className="TableDataC"         style={{ backgroundColor: coachV.paye ? "#d4edda" : "inherit" }} 
      >
        <img src={getImageUrl(coachV.image)} alt="Article" className="TableDataimgC" />
      </td>
      <td className="TableDataC"         style={{ backgroundColor: coachV.paye ? "#d4edda" : "inherit" }} 
      >
        <Link to={`/admin/Coachs/visible/view/${coachV._id}`}>
          <GrView className="IconData" title="View coach" />
        </Link>
        <RiDeleteBin6Line
          className="IconData"
          onClick={() => handleDelete(coachV._id)} 
          title="Delete coach"
        />
        <Link to={`/admin/Coachs/visible/edit/${coachV._id}`}>
          <CiEdit className="IconData" title="Edit coach" />
        </Link>
        <MdOutlinePaid className="IconData"  title="Paye coach" onClick={() => handlePaye(coachV._id)} />
        <FiSend className="IconData" title="Send Email" onClick={() => handlesend(coachV.email)} />
      </td>
    </tr>
  ))}
</tbody>

          </table>
        </div>
      </div>
    </>
  );
};

export default CoachVisib;
