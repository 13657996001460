import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import image from "../../images/big_image_2.jpg";
import Newsletter from "./Newsletter";
import Footer from "./Footer";
import './css/forget.css';
import { checkEmailExistence } from "../../Redux/Slice/CoachSlice";

const Forgett = () => {
  const { isAuth, emailExists } = useSelector((state) => state.coach);
  const [message, setmessage] = useState(""); // State to handle the error message

  const [formSubmitted, setFormSubmitted] = useState(false); // State to track if the form is submitted
  const email = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) navigate("/coach/profil");
  }, [isAuth, navigate]);

  const handleForgetFormSubmit = async (e) => {
    e.preventDefault();

    const emailValue = email.current.value;
    dispatch(checkEmailExistence(emailValue));
    setFormSubmitted(true);
    if (emailExists==='false') {
        setmessage("Cet Email n'est pas existant !");
      } else {
        setmessage("Votre nouveau mot de passe vous sera envoyé par mail!"); // Clear error if email exists
        // Perform additional actions like sending a reset link or navigating
        // navigate("/path_to_next_step");
      }
    } 
  

  return (
    <>
      <div
        className="PlatformeForget"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div>
          <p className='TitreForget'>
            Bienvenue sur votre plateforme MonCoach.tn
          </p>
        </div>
      </div>
      <div className="containerForget">
        <form className="Form-Forget" onSubmit={handleForgetFormSubmit}>
          {!formSubmitted ? (
            <>
              <label className="ConnectForget">Ajouter votre Email:</label>
              <div className="input-Forget">
                <input
                  type="email"
                  name="email"
                  className="input"
                  ref={email}
                  placeholder="Email "
                  style={{ paddingLeft: "10px" }}
                />
              </div>
              <button type="submit" className="Connecter">Envoyer</button>
            </>
          ) : (
            <p>{message}</p>
          )}
        </form>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Forgett;
