import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import loadingGif from './../../../images/loading.gif';
import './css/editPub.css';
import { getImageUrl } from '../../..';
import { GetPublication, PutPublic } from '../../../Redux/Slice/PubAtelierSlice';

const EditPub = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { pubatelier } = useSelector((state) => state.pubatelier);
    const [formData, setFormData] = useState({
        titre: '',
        texte: '',
        img: ''
    });
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        setEditorLoaded(true);
        dispatch(GetPublication(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (pubatelier && id) {
            const pubToEdit = pubatelier.find(pub => pub._id === id);
            if (pubToEdit) {
                setFormData(pubToEdit);
            }
        }
    }, [pubatelier, id]);

    const handleEditorChange = (event, editor) => {
        const content = editor.getData();
        setFormData(prevData => ({
            ...prevData,
            texte: content
        }));
    };

    const handleTitreChange = (event, editor) => {
        const content = editor.getData();
        setFormData(prevData => ({
            ...prevData,
            titre: content
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = { titre: formData.titre, texte: formData.texte };
        dispatch(PutPublic({ id, data: formDataToSend }))
            .then(() => {
                navigate('/admin/atelier-A');
            })
            .catch((error) => {
                console.error('Failed to update publication:', error);
            });
    };

    if (!editorLoaded) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src={loadingGif} alt="Chargement..." />
            </div>
        );
    }

    return (
        <div className='edit-Pub'>
            <div className='container-Edit-Pub'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='LabelEditPub'>Titre</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.titre}
                            onChange={handleTitreChange}
                        />
                    </div>

                    <div>
                        <label className='LabelEditPub'>Texte</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.texte}
                            onChange={handleEditorChange}
                        />
                    </div>

                    <div className="form-group">
                        <label className='LabelEditPub'>Image</label>
                        {formData.img && <img src={getImageUrl(formData.img)} alt="Pub" className='imageEdit' />}
                    </div>

                    <div className='buttonsContainer'>
                        <button type="submit" className='btnEditPub'>Modifier</button>
                        <button type="button" className='btnAnnulPub' onClick={() => navigate('/admin/Accueil')}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPub;
