import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import BarheaderAdmin from '../BarheaderAdmin';
import NavBarAdmin from '../NavBarAdmin';
import "./css/EmailingPub.css";
import DOMPurify from 'dompurify';
import { sendEmail } from '../../../Redux/Slice/emailSlice';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EmailingPub = () => {
  const { Lists } = useSelector((state) => state.list);
  const [emailMessage, setEmailMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendEmail = (e) => {
    e.preventDefault();
    const selectedListEmails = Lists.map((List) => List.mail);
    const cleanedHtml = DOMPurify.sanitize(emailMessage);

    dispatch(sendEmail({ email: selectedListEmails, subject: subject, message: cleanedHtml }));

    // Display the alert
    setShowAlert(true);

    // Hide the alert and navigate after a delay
    setTimeout(() => {
      setShowAlert(false);
      navigate('/admin/atelier-A');
    }, 3000); // 3-second delay before navigating
  };

  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />

      {/* Full-width alert at the top */}
      <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1200 }}>
        {showAlert && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ marginBottom: '20px', width: '100%' }}>
            Email envoyé avec succès !
          </Alert>
        )}
      </div>

      <div className="ConsultEmail" style={{ paddingTop: showAlert ? '70px' : '20px' }}>
        <div className="ConsultEmailContainer">
          <h3 className="EmailingC">Emailing</h3>
          <label>Objet:</label>
          <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          <label>Message:</label>
          <CKEditor
            editor={ClassicEditor}
            data={emailMessage}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEmailMessage(data);
            }}
          />
          <button onClick={handleSendEmail} className="SendEmail">Envoyer</button>
        </div>
      </div>
    </>
  );
}

export default EmailingPub;
