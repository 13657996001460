import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const payement = createAsyncThunk('/payement', async (_, { rejectWithValue }) => {
  try {
    const res = await axios.post('/pay');
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const verifPayement = createAsyncThunk('Payement/verif', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const res = await axios.post(`/pay/${id}`);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});



const PayementSlice = createSlice({
  name: "Payement",
  initialState: {
    PayemtLink: [],
    status_Payement :"",
    isLoading: false,
    error: null,
    token: localStorage.getItem("token") || null,
    isAuth: localStorage.getItem("isAuth") || false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(payement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(payement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.isAuth = true;
        state.PayemtLink = action.payload.url.payUrl;
      })
      .addCase(payement.rejected, (state, action) => {
        state.isLoading = false;
        state.token = null;
        state.isAuth = false;
        state.error = action.payload.error;
      })
      .addCase(verifPayement.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifPayement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.isAuth = true;
        state.status_Payement = action.payload.status.payment.status;
      })
      .addCase(verifPayement.rejected, (state, action) => {
        state.isLoading = false;
        state.token = null;
        state.isAuth = false;
        state.error = action.payload.error;
      })
     
  },
});

export default PayementSlice.reducer;
