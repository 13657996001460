import React, { useEffect } from "react";
import image from "../../images/big_image_2.jpg";
import "./css/videocoach.css";
import { useDispatch, useSelector } from "react-redux";
import VideoCard from "./VideoCard";
import Newsletter from "../coach/Newsletter";
import Footer from "../coach/Footer";
import { Getvideo } from "../../Redux/Slice/videoSlice";
const Videocoach = () => {
  const dispatch = useDispatch();

  const { video } = useSelector((state) => state.video);
  useEffect(() => {
    dispatch(Getvideo());
  }, [dispatch]);
  useEffect(() => {
    const handleScroll = () => {
      const elem = document.querySelector('.section-hero');
      const { top } = elem.getBoundingClientRect();
      if (elem) {
        const backgroundPositionY = -(top +25) * 0.6;
        elem.style.backgroundPosition = `50% ${backgroundPositionY}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className="ImagePlatformeVedio section-hero"
        style={{
        
          backgroundImage: `url(${image})`,

        }}
      >
  
          <h3 className="Vedio-Titre">VidéoCoaching</h3>
    
      </div>

      <div className="videoCoach">
        <div className="videoCoachContainer">
          <h1 className="videoTitre">
            Parcourez nos vidéos sur la chaîne YouTube : MonCoach.tn
          </h1>
          <div className="video-list">
            {video && video.map((Vid,index) => <VideoCard key={index} video={Vid} />)}
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Videocoach;
