import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetIcon, PutIcon } from '../../../Redux/Slice/IconSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import loadingGif from './../../../images/loading.gif';
import './css/edit.css';
import { getImageUrl } from '../../..';

const Edit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { Icon } = useSelector((state) => state.icon);
    const [formData, setFormData] = useState({
        Titre: '',
        Texte: '',
        image: ''
    });
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        setEditorLoaded(true);
        dispatch(GetIcon());
    }, [dispatch]);

    useEffect(() => {
        if (Icon && id) {
            const iconToEdit = Icon.find(icon => icon._id === id);
            if (iconToEdit) {
                setFormData(iconToEdit);
            }
        }
    }, [Icon, id]);

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData(prevData => ({
            ...prevData,
            Texte: data
        }));
    };

    const handleTitreChange = (event, editor) => {
        const data = editor.getData();
        setFormData(prevData => ({
            ...prevData,
            Titre: data
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = { Titre: formData.Titre, Texte: formData.Texte };
        dispatch(PutIcon({ id, data: formDataToSend }));
        navigate('/admin/consulter_icon');
    };

    if (!editorLoaded) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <img src={loadingGif} alt="Chargement..." />
            </div>
        );
    }

    return (
        <div className='edit-Icon'>
            <div className='container-Edit-Icon'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='LabelEditIcon'>Titre</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.Titre}
                            onChange={handleTitreChange}
                        />
                    </div>

                    <div>
                        <label className='LabelEditIcon'>Texte</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.Texte}
                            onChange={handleEditorChange}
                        />
                    </div>

                    <div className="form-group">
                        <label className='LabelEditIcon'>Icone</label>
                        {formData.image && <img src={getImageUrl(formData.image)} alt="Icone" className='imageEdit' />}
                    </div>

                    <div className='buttonsContainer'>
                        <button type="submit" className='btnEditIcon'>Modifier</button>
                        <button type="button" className='btnAnnulIcon' onClick={() => navigate('/admin/Accueil')}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Edit;
