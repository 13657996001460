import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,

  LinkedinShareButton,

} from "react-share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { BASE_URL_back, getImageUrl } from "../../index.js";
import logo from "../../images/logo.jpg";
import './css/ListPub.css';
import { GetPublication } from "../../Redux/Slice/PubAtelierSlice.js";
import { Atelier_OG } from "../../Redux/Slice/AtelierSlice.js";

const PartagePub = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const Publi = useSelector((state) =>
    state.pubatelier.pubatelier.find((pub) => pub._id === id)
  );

  useEffect(() => {
    console.log(Publi)
    if (!Publi) {
      dispatch(GetPublication(id)); // Fetch event by ID if not already available in state
    }
  }, [dispatch, id, Publi]);
  useEffect(() => {
 
   

    
   
  console.log(Publi)
  if(Publi){
  dispatch(Atelier_OG(Publi._id))
  console.log(Publi._id)
}
}, [dispatch,Publi]);
  const shareUrl = `${BASE_URL_back}/atelier_D/${id}`;

  return (
    <>
      
        <div style={{ padding: "40px" }}>
          <img src={logo} alt="LOGO" style={{ width: '180px' }} />
          <hr />
          <img src={getImageUrl(Publi.img)} alt="atelier_degustation" style={{ display: "block", margin: "0 auto", width: '300px' }} />
          <h3 className="Bub-titre">{Publi.titre}</h3>
          <div className="Bub-inf">
            <div className='partageBub' style={{ display: "flex", justifyContent: 'center', padding: "20px" }}>
              <div>
                <FacebookShareButton url={shareUrl} quote={Publi.titre} >
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#0965FE',
                    paddingRight: '5px'
                  }}>
                    <FacebookIcon   style={{color:"#fff" }}  size={20}  quote={Publi.titre}/>
                    <h3 className='info-item'>Partage</h3>
                  </div>
                </FacebookShareButton>
              </div>
              <div>
                <LinkedinShareButton url={shareUrl}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#0077B5',
                    paddingRight: '5px'
                  }}>
                    <LinkedInIcon   style={{color:"#fff" }}  size={20} />
                    <h3 className='info-item'>Partage</h3>
                  </div>
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          <hr />
          <div className="Bub-Descrip" dangerouslySetInnerHTML={{ __html: Publi.texte }} />
        </div>
   
    </>
  );
}

export default PartagePub;
